import { Button } from "@dnb/eufemia";
import styled from "styled-components";

export const HeaderContainer = styled.nav`
    display:grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-start: 1;
    justify-items: center;
    align-items: center;
    height: 80px;
    width: 100%;
    position: fixed;
    background-color: transparent;
    padding-top: 50px;
    padding-bottom: 50px;
`;

export const CVAHeader = styled.p`
    color: #f8f8f8;
`;

export const ImgHeader = styled.img`
    cursor: pointer;
`;

export const LogoutButton = styled(Button)`
background-color: var(--color-ocean-green);
`;
