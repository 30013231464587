
import styled from "styled-components";
import {Button} from '@dnb/eufemia'
import { CommonContainer } from "../../commonStyles/commonStyles";

export const CaseDetailsPageContainer = styled.div`
display: grid;
grid-template-columns: auto 1fr;
column-gap: 3rem;
padding: 4rem;
`;

export const CaseDetailsPageLeftContainer = styled.div`
display: grid;
grid-column-start: 1;
row-gap: 1rem;
grid-template-rows: auto auto auto;
`;

export const CaseDetailsPageRightContainer = styled.div`
display: flex;
grid-column-start: 2;
flex-direction: column;
max-width: fit-content;
gap: 10px;
`;

export const CaseSummaryInfoContainer = styled(CommonContainer)`
display: grid;

padding: 24px;
gap: 8px;
background: #f4fbf9;
`;

export const BackButton = styled(Button)`
width: fit-content;
`;

export const SkeletonButton = styled(Button)`
width: 240px;
`;
