
const getPropertyValue = <T>(o: T, propertyName: String)=> {
  type ObjectKey = keyof T;
  const fieldkey = propertyName as ObjectKey;
  return getValue(o,fieldkey)
} 

const getValue = <T, K extends keyof T>(o: T, propertyName: K): T[K] => o[propertyName]

export default getPropertyValue
