import { ConfigProps } from "./ConfigProps";

export class prod implements ConfigProps{
    API_BASE_URL: string;
    CLIENT_ID: string;
    AUTHORITY: string;
    environment: "prod";

    constructor(){
        this.API_BASE_URL = "https://jwd3edtdn5aezg3rmhjbhhbz44.appsync-api.eu-west-1.amazonaws.com/graphql"
        this.CLIENT_ID = "0af58f94-4ddc-49c2-8c0f-804eb03fb881"
        this.AUTHORITY = "https://login.microsoftonline.com/4cbfea0a-b872-47f0-b51c-1c64953c3f0b"
        this.environment = "prod"
    }

}
