
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { IMsalContext, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { createAsyncThunk } from "@reduxjs/toolkit";
import request, { gql } from "graphql-request";
import { transformCaseViewSummary, transformCustomer} from "../helper/DataTransform";
import { RootState } from "./store";
import AuthToken from "./types/Authtoken";
import CustomerCases from "./types/CustomerCases";
import CustomerData from "./types/CustomerData";
import { CaseViewDetailsResponse, CaseViewResponse, CaseViews, Customer, CustomerResponse, PartyInfo, PartyResponse } from "./types/servertypes/servertypes";
import { configuration, pca } from "..";


 const getAzureADToken = async () => {

  const request = {
    scopes: ["User.Read"]
  };

  let token = "";
  try {
    const response = await pca.acquireTokenSilent(request);
    token = response.accessToken
  } catch (error) {
    const response = await pca.acquireTokenPopup(request)
    token = response.accessToken
  }
  
  return {
    'Authorization': token
  }
  
 }

export const fetchParty = createAsyncThunk<PartyInfo, String>('caseview/fetchParty', async(partyId: String, thunkAPI)=> {
  const state = thunkAPI.getState() as RootState; 

  const fields = state.configuration.party

  var fiieldsToFetch = fields.join("\n")

  const query = gql`
      query($partyId:String!){
        getPartyById(partyId: $partyId) {
          `+ fiieldsToFetch +`
        }
      }`

  const variables = { partyId }
 
  const headers = await getAzureADToken()

  const response: PartyResponse = await request(configuration.API_BASE_URL, query, variables,headers)

  const party: PartyInfo = {customerNumber: response.getPartyById.customerNumber, partyId: response.getPartyById.partyId, partyType: '', partyStatus: ''}

  return party

})

export const fetchCustomer =  createAsyncThunk<CustomerData,String>('caseview/fetchCustomer', async(partyId: String, thunkAPI)=> {

  const state = thunkAPI.getState() as RootState; 

  const fields = state.configuration.customer

  var fiieldsToFetch = fields.join("\n")

  const query = gql`
      query($partyId:String!){
        getCustomerById(partyId: $partyId) {
          `+ fiieldsToFetch +`
          }
      }`

  const variables = { partyId }

  const headers = await getAzureADToken()

  const response: CustomerResponse = await request(configuration.API_BASE_URL, query, variables, headers)

  const customerServerResponse: Customer = {customerType: response.getCustomerById.customerType, givenName: response.getCustomerById.givenName, fullName: response.getCustomerById.fullName, familyName: response.getCustomerById.familyName,  lifeCycleStatus: response.getCustomerById.lifeCycleStatus, ssn: response.getCustomerById.ssn}

  return transformCustomer(customerServerResponse, fields)
})


export const fetchCaseSummary = createAsyncThunk<CustomerCases,String>('caseview/fetchCaseSummary', async(partyId: String, thunkAPI)=> {

  const state = thunkAPI.getState() as RootState; 

  const fields = state.configuration.case

  var fiieldsToFetch = fields.join("\n")

    const query = gql`
    query($partyId:String!, $nameSpace:String!){
      getCasesByPartyID(partyId: $partyId, nameSpace: $nameSpace) {
          caseViews{
            caseSummary{
              `+ fiieldsToFetch +`
            }
        }
      }
   }`

   const nameSpace = "retail-customer"
   const variables = { partyId, nameSpace}

   const headers = await getAzureADToken()

   const response: CaseViewResponse = await request(configuration.API_BASE_URL, query, variables, headers)
   
   return transformCaseViewSummary(response.getCasesByPartyID, fields)
})

export const fetchCasesDetails = createAsyncThunk<CaseViewDetailsResponse,string>('caseview/fetchCasesDetails', async(caseId: string, thunkAPI)=> {
  const state = thunkAPI.getState() as RootState; 
  const fields = state.configuration.businessProcess
  var fiieldsToFetch = fields.join("\n")

  const query = gql`
   query($caseId:String!, $nameSpace:String!) {
    getDetailsByCaseID(caseId: $caseId, nameSpace: $nameSpace) {
      caseSummary {
        caseId
        createdAt
        messageToCustomer
        namespace
        productName
        stage
        status
        updatedAt
      }
      processes {
        additionalDetails
        attachments
        mandatory
        name
        sequence
        status
        type
      }
    }
  }`
 
 const nameSpace = "retail-customer"
 
 const variables = { caseId, nameSpace}

 const headers = await getAzureADToken()

 const response: CaseViewDetailsResponse = await request(configuration.API_BASE_URL, query, variables, headers)
 return response
})

