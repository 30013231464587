import React, { useEffect, useState } from 'react'
import {HeaderContainer, ImgHeader} from './style'
import { useMsal } from '@azure/msal-react'
import logo from '../../assets/cva.svg'; 
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
//import { resetStore } from '../..';

function Header() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const {instance} = useMsal()

  const [name, setName] = useState<string>()

  useEffect(()=>{
    const currentAccount = instance.getActiveAccount()
    if(currentAccount){
      setName(currentAccount.name)
    }
  },[instance])

  return (
    <HeaderContainer>
        <ImgHeader src={logo} onClick={() => navigate("/cases")}/>
      </HeaderContainer>
  )
}

export default Header