
import styled from "styled-components";
import {Button} from '@dnb/eufemia'


export const MainContainer = styled.div`
    display: grid;
    grid-template-rows: 80px 1fr;
`;



export const BodyContainer = styled.div`
    grid-row-start: 2;
    display: grid;
`;