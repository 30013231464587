import styled from "styled-components";
import { CommonButton, CommonContainer } from "../commonStyles/commonStyles";

export const CaseSummaryButton = styled(CommonButton)`

`;

export const CaseSummaryContainer = styled(CommonContainer)`
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 16px 32px;
  background: #f4fbf9;
`;

export const CaseIdContainer = styled.div`
  color: gray;
  align-self: end;
  font-family: "DNB";
`;

export const DateContainer = styled.div`
  color: gray;
  align-self: start;
  font-family: "DNB";
  width: 150px;
  text-align: left;
`;

export const SummaryProperties = styled.div`
  font-family: "DNB";
  font-style: normal;
`;

export const SummaryKey = styled.span`
  font-weight: bold;
  font-family: "DNB";
`;

export const CaseSummaryHeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  font-family: "DNB";
`;

export const CaseSummaryTitle = styled.div`
  font-weight: bold;
  font-family: "DNB";
  padding-bottom: 5px;
`;
