import { Button } from "@dnb/eufemia"
import { Process } from "../../redux/types/servertypes/servertypes"
import { convertCamelCaseToReadable } from "../../helper/Utils"
import { ErrorButton, OpenButton, RejectedButton } from "./style"


interface ButtonProcessProps {
    process: Process,
    handleClick(): void
}

function ProcessButtonComponent(buttonProcessProps: ButtonProcessProps) {

  const status = buttonProcessProps.process.status != null? buttonProcessProps.process.status.toLowerCase() : "";

  console.log("status" + status);

  if (status === "completed"){
    return (
      <Button 
        text={convertCamelCaseToReadable(buttonProcessProps.process.name)}  
        icon="chevron_right" 
        on_click={buttonProcessProps.handleClick}
        />
    )
  }
  if (status ===  "inprogress" || status === ""){
    return (
      <Button 
        text={buttonProcessProps.process.name}  
        icon="chevron_right" 
        variant="signal"  
        on_click={buttonProcessProps.handleClick}
        />
    )
  }
  if (status ===  "rejected"){
    return (
      <RejectedButton 
        text={buttonProcessProps.process.name}  
        icon="chevron_right" 
        variant="unstyled" 
        on_click={buttonProcessProps.handleClick}
        />
    )
  }
  if (status ===  "error"){
    return (
      <ErrorButton 
        text={buttonProcessProps.process.name}  
        icon="chevron_right" 
        variant="unstyled"
        on_click={buttonProcessProps.handleClick}
        />
    )
  }
  if (status === "open"){
    return (
      <OpenButton 
        text={convertCamelCaseToReadable(buttonProcessProps.process.name)}  
        icon="chevron_right" 
        variant="unstyled"
        disabled
      />
    )
  }
  return (<></>)
}

export default ProcessButtonComponent